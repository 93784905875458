import { render, staticRenderFns } from "./Product001.vue?vue&type=template&id=b6543418&scoped=true&"
import script from "./Product001.vue?vue&type=script&lang=js&"
export * from "./Product001.vue?vue&type=script&lang=js&"
import style0 from "./Product001.vue?vue&type=style&index=0&id=b6543418&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6543418",
  null
  
)

export default component.exports